<template>
  <div>
    <resizable-table
      class="mt-3"
      style="flex: 1"
      table_name="diagnosis-list-tabl"
      :items="items"
      :show_product_search="false"
      :default_fields="fields"
    >
      <template #head_id>
        <b-checkbox v-model="selected_all" />
      </template>
      <template #body_id="{ value }">
        <b-checkbox
          v-model="selected"
          :value="value"
        />
      </template>
      <template #body_remainder_entersight="data">
        <span class="d-flex float-right">
          {{ getRemainders(data.item.remainders) || 0 }}
        </span>
      </template>
      <template #body_remainder_egais="data">
        <span class="d-flex float-right">
          {{ egaisRemainders(data.item.productExternalId) }}
        </span>
      </template>
      <template #body_volume="data">
        <span class="d-flex float-right">
          {{ getEgaisParams(data.item.productExternalId)?.volume }}
        </span>
      </template>
      <template #body_strength="data">
        <span class="d-flex float-right">
          {{ getEgaisParams(data.item.productExternalId)?.strength }}
        </span>
      </template>
      <template #body_alco_type="data">
        <span class="d-flex float-right">
          {{ getEgaisParams(data.item.productExternalId)?.type }}
        </span>
      </template>
      <template #body_name="data">
        <div class="text-left">
          <img
            v-if="data.item.hide"
            v-b-tooltip.hover.top
            src="/img/icons/docs/warning.svg"
            class="mr-2 cursor"
            title="Обнаружен новый товар. Создайте товарную карточку или привяжите к существующей"
            @click="showBindingModal(data.item)"
          />{{ data.value }}
          <div class="b-name article">
            <template v-if="data.item.hide">
              <span
                style="text-decoration: underline; color: #00a3ff"
                class="cursor"
                @click="showBindingModal(data.item)"
              >
                Привязать к товару
              </span>
            </template>
            <template v-else>
              <div
                v-for="(item, index) of data.item.productExternalId"
                :key="index"
                class="ext_id"
              >
                {{ item.external_id }}
              </div>
            </template>
          </div>
        </div>
      </template>
      <template #body_manufacturer="{ value }">
        <span class="d-flex float-left">
          {{ value?.name || value?.fullname }}
        </span>
      </template>
    </resizable-table>
    <table-navbar
      :items="selected"
      items_name="egais-product"
      hide_print
      hide_remove
      :print="false"
      @write_off="remove_items"
      @link="link"
    />
    <pagination
      :total="total"
      @change_pagination="changePagination"
    />
  </div>
</template>
<script>
  import Pagination from '@/views/shared/components/table/Pagination.vue'
  import TableNavbar from '@/views/shared/components/table/TableNavbar.vue'
  import { ProductModel } from '@/models/product.model'
  import ResizableTable from '@/components/ResizableTable'
  import { mapGetters } from 'vuex'

  export default {
    components: { TableNavbar, Pagination, ResizableTable },
    data() {
      return {
        selectedBindingProduct: null,
        selected_all: false,
        selected: [],
        total: 0,
        items: [],
        fields: [
          {
            key: 'id',
            label: '',
            checked: true,
            width: 50
          },
          {
            key: 'order',
            label: '№',
            checked: true,
            width: 50
          },
          {
            key: 'name',
            label: 'Наименование',
            checked: true,
            width: 300
          },
          {
            key: 'remainder',
            label: 'Остаток',
            checked: true,
            width: 300,
            children: [
              {
                key: 'remainder_egais',
                label: 'ЕГАИС',
                checked: true,
                width: 150
              },
              {
                key: 'remainder_entersight',
                label: 'По учету',
                checked: true,
                width: 150
              }
            ]
          },
          {
            key: 'volume',
            label: 'Объём, Л',
            checked: true,
            width: 150
          },
          {
            key: 'strength',
            label: 'Крепость, %',
            checked: true,
            width: 150
          },
          {
            key: 'alco_type',
            label: 'Вид продукции',
            checked: true,
            width: 150
          },
          {
            key: 'manufacturer',
            label: 'Производитель',
            checked: true,
            width: 300
          }
        ]
      }
    },
    apollo: {
      ProductSearchRemErrored: {
        query: require('../gql/ProductSearchErrored.graphql'),
        deep: true,
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              pagination: this.pagination,
              name: '',
              branch: this.currentBranch.id
            }
          }
        },
        result({ data }) {
          this.items = data.ProductSearchRemErrored.products
          this.total = data.ProductSearchRemErrored.total
        }
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },
    methods: {
      getRemainders(remainders) {
        return remainders?.reduce((sum, el) => sum + el?.value, 0)
      },
      egaisRemainders(remainders) {
        return remainders?.reduce((sum, el) => sum + el?.egaisParams.remainder, 0)
      },
      showBindingModal(item) {
        this.selectedBindingProduct = new ProductModel(item)
        this.$bvModal.show('product-binding-modal')
      },
      getEgaisParams(params) {
        for (const param of params) {
          if (param?.egaisParams?.id) return param.egaisParams
        }
        return {}
      },
      async remove_items() {
        await this.$apollo.mutate({
          mutation: require('../gql/WriteOffByAlco.graphql'),
          variables: {
            input: {
              ids: this.items
                .filter((el) => this.selected.includes(el.id))
                .map((el) => el.productExternalId?.map((el) => el.external_id))
                .flat(Infinity),
              branch: this.currentBranch.id
            }
          }
        })
        this.selected = []

        await this.$apollo.queries.ProductSearchRemErrored.refetch()
      },
      link() {
        this.showBindingModal(this.items.find((el) => el.id === this.selected[0]))
      },
      changePagination(pagination) {
        this.pagination = {
          skip: (pagination.current_page - 1) * pagination.take,
          take: pagination.take
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  th.cursor:not(:nth-child(1)) {
    .td-div {
      display: flex !important;
      float: left !important;
      justify-content: flex-start !important;
      width: 100% !important;
      span {
        width: 100% !important;
        display: flex !important;
        float: left !important;
        justify-content: flex-start !important;
      }
    }
  }
  .ext_id {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #888888;
    padding-right: 5px;
  }

  .product_name {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #313131;
  }

  .diagnosis-list {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .b-name {
    &.article {
      color: #888888;
    }
  }

  .b-name.article {
    line-height: 17px;
    margin-top: 3px;
  }

  .b-name {
    &.article {
      font-size: 12px;
      line-height: 24px;
      color: #888888;
      margin-top: 5px;
    }
  }
</style>
