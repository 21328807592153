var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('resizable-table', {
    staticClass: "mt-3",
    staticStyle: {
      "flex": "1"
    },
    attrs: {
      "table_name": "diagnosis-list-tabl",
      "items": _vm.items,
      "show_product_search": false,
      "default_fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('b-checkbox', {
          model: {
            value: _vm.selected_all,
            callback: function ($$v) {
              _vm.selected_all = $$v;
            },
            expression: "selected_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var value = _ref.value;
        return [_c('b-checkbox', {
          attrs: {
            "value": value
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })];
      }
    }, {
      key: "body_remainder_entersight",
      fn: function (data) {
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.getRemainders(data.item.remainders) || 0) + " ")])];
      }
    }, {
      key: "body_remainder_egais",
      fn: function (data) {
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.egaisRemainders(data.item.productExternalId)) + " ")])];
      }
    }, {
      key: "body_volume",
      fn: function (data) {
        var _vm$getEgaisParams;

        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s((_vm$getEgaisParams = _vm.getEgaisParams(data.item.productExternalId)) === null || _vm$getEgaisParams === void 0 ? void 0 : _vm$getEgaisParams.volume) + " ")])];
      }
    }, {
      key: "body_strength",
      fn: function (data) {
        var _vm$getEgaisParams2;

        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s((_vm$getEgaisParams2 = _vm.getEgaisParams(data.item.productExternalId)) === null || _vm$getEgaisParams2 === void 0 ? void 0 : _vm$getEgaisParams2.strength) + " ")])];
      }
    }, {
      key: "body_alco_type",
      fn: function (data) {
        var _vm$getEgaisParams3;

        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s((_vm$getEgaisParams3 = _vm.getEgaisParams(data.item.productExternalId)) === null || _vm$getEgaisParams3 === void 0 ? void 0 : _vm$getEgaisParams3.type) + " ")])];
      }
    }, {
      key: "body_name",
      fn: function (data) {
        return [_c('div', {
          staticClass: "text-left"
        }, [data.item.hide ? _c('img', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          staticClass: "mr-2 cursor",
          attrs: {
            "src": "/img/icons/docs/warning.svg",
            "title": "Обнаружен новый товар. Создайте товарную карточку или привяжите к существующей"
          },
          on: {
            "click": function ($event) {
              return _vm.showBindingModal(data.item);
            }
          }
        }) : _vm._e(), _vm._v(_vm._s(data.value) + " "), _c('div', {
          staticClass: "b-name article"
        }, [data.item.hide ? [_c('span', {
          staticClass: "cursor",
          staticStyle: {
            "text-decoration": "underline",
            "color": "#00a3ff"
          },
          on: {
            "click": function ($event) {
              return _vm.showBindingModal(data.item);
            }
          }
        }, [_vm._v(" Привязать к товару ")])] : _vm._l(data.item.productExternalId, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "ext_id"
          }, [_vm._v(" " + _vm._s(item.external_id) + " ")]);
        })], 2)])];
      }
    }, {
      key: "body_manufacturer",
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s((value === null || value === void 0 ? void 0 : value.name) || (value === null || value === void 0 ? void 0 : value.fullname)) + " ")])];
      }
    }])
  }), _c('table-navbar', {
    attrs: {
      "items": _vm.selected,
      "items_name": "egais-product",
      "hide_print": "",
      "hide_remove": "",
      "print": false
    },
    on: {
      "write_off": _vm.remove_items,
      "link": _vm.link
    }
  }), _c('pagination', {
    attrs: {
      "total": _vm.total
    },
    on: {
      "change_pagination": _vm.changePagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }